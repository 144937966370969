import React from "react";


import App from "./App";

export default function Home() {
  return (
    <div>
      <App/>
    </div>
  );
}
